import * as React from "react";
import { Box } from "@material-ui/core";
import {
  FlightCategoryToggle,
  FlightCategoryToggleTripCategory,
  DesktopPopupModal,
  Icon,
  IconName,
  B2BButton,
  NotificationBanner,
  BannerSeverity,
  PassengerCountPickerType,
  PolicyDetailsModal,
  PolicyModalButton,
} from "halifax";
import {
  CHANGED_PAX_COUNT,
  CHANGED_TRIP_TYPE,
  ITripTerminus,
  TripCategory,
  IPassengerCounts,
  PolicyTier,
  ModalScreens,
  POLICY_MODAL,
  VIEWED_POLICY_MODAL,
  SessionInfo,
  CorpSessionInfo,
} from "redmond";

import "./styles.scss";
import { CalendarPickerButton } from "./components";
import { FlightSearchButton } from "../SearchButton";
import {
  OriginAutocomplete,
  DestinationAutocomplete,
} from "./components/TerminusAutocomplete";
import { PassengerCountPicker } from "./components/PassengerCountPicker";
import * as textConstants from "./textConstants";
import { FlightSearchControlConnectorProps } from "./container";
import { RouteComponentProps } from "react-router";
import * as H from "history";
import * as constants from "./constants";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

export interface IFlightSearchControlProps
  extends FlightSearchControlConnectorProps,
    RouteComponentProps {
  policies: PolicyTier | undefined;
  sessionInfo?: SessionInfo | CorpSessionInfo;
}

interface IFlightSearchControlState {
  openFlightSearchFiltersModal: boolean;
}

interface Termini {
  destination: ITripTerminus | null;
  origin: ITripTerminus | null;
}

interface IFlightSearchControlState {
  openPassengerCountPicker: boolean;
  openFlightSearchFiltersModal: boolean;
  hasMissingSearchInfoError: boolean;
  isPolicyModalOpen: boolean;
}

export class FlightSearchControl extends React.Component<
  IFlightSearchControlProps,
  IFlightSearchControlState
> {
  constructor(props: IFlightSearchControlProps) {
    super(props);
    this.state = {
      openPassengerCountPicker: false,
      openFlightSearchFiltersModal: false,
      hasMissingSearchInfoError: false,
      isPolicyModalOpen: false,
    };
  }

  componentDidUpdate(prevProps: IFlightSearchControlProps) {
    const {
      destination: prevDestination,
      origin: prevOrigin,
      tripCategory: prevTripCategory,
    } = prevProps;
    const {
      destination,
      origin,
      fetchDepartureCalendar,
      setCalendar,
      departureDate,
      tripCategory,
    } = this.props;

    if (
      (origin &&
        destination &&
        this.hasTerminiChanged(
          { destination: prevDestination, origin: prevOrigin },
          { origin, destination }
        )) ||
      (tripCategory &&
        this.hasTripCategoryChanged(prevTripCategory, tripCategory))
    ) {
      fetchDepartureCalendar(
        origin as ITripTerminus,
        destination as ITripTerminus
      );
    } else if (!(origin || destination) && !departureDate) {
      setCalendar();
    }
  }

  static getDerivedStateFromProps(props: IFlightSearchControlProps) {
    const { destination, origin, departureDate, tripCategory, returnDate } =
      props;
    if (
      !!destination &&
      !!origin &&
      !!departureDate &&
      (tripCategory === TripCategory.ROUND_TRIP ? !!returnDate : true)
    ) {
      return {
        hasMissingSearchInfoError: false,
      };
    }
    return null;
  }

  hasTripCategoryChanged(
    prevTripCategory: TripCategory,
    tripCategory: TripCategory
  ): boolean {
    return prevTripCategory !== tripCategory;
  }

  hasTerminiChanged(
    { destination: prevDestination, origin: prevOrigin }: Termini,
    { destination, origin }: Termini
  ): boolean {
    const extractCode = (terminus: ITripTerminus | null) =>
      terminus && terminus.id && terminus.id.code ? terminus.id.code.code : "";

    const prevDestinationCode = extractCode(prevDestination);
    const prevOriginCode = extractCode(prevOrigin);
    const originCode = extractCode(origin);
    const destinationCode = extractCode(destination);

    return (
      prevDestinationCode !== destinationCode || prevOriginCode !== originCode
    );
  }

  handleInputChange =
    (valueSetter: (value: number) => void) =>
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      valueSetter(parseInt(event.target.value, 10));
    };

  handleTravelersSelected = () =>
    this.setState({ openPassengerCountPicker: true });

  handleTravelersChanged = (counts: PassengerCountPickerType) => {
    this.setState({ openPassengerCountPicker: false });

    const {
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
    } = counts as IPassengerCounts;

    trackEvent({
      eventName: CHANGED_PAX_COUNT,
      properties: {
        ...this.props.airEntryProperties,
        pax_total:
          adultsCount + childrenCount + infantsInSeatCount + infantsOnLapCount,
        trip_type: this.props.tripCategory,
      },
    });
  };

  closeFlightSearchFiltersModal = () =>
    this.setState({ openFlightSearchFiltersModal: false });

  handleSearch = (history: H.History) => {
    this.props.populateFlightShopQueryParams({
      history,
      useHistoryPush: true,
      forceQueryUpdate: false,
    });
    this.props.resetFilters();
  };
  handleSearchClick = (history: H.History) => {
    this.props.destination &&
    this.props.origin &&
    this.props.departureDate &&
    (this.props.tripCategory === TripCategory.ROUND_TRIP
      ? this.props.returnDate
      : true)
      ? this.handleSearch(history)
      : this.setState({ hasMissingSearchInfoError: true });
  };

  handleSetTripCategory = (
    tripCategory: TripCategory.ONE_WAY | TripCategory.ROUND_TRIP
  ) => {
    this.props.setTripCategory(tripCategory);
    trackEvent({
      eventName: CHANGED_TRIP_TYPE,
      properties: { ...this.props.airEntryProperties, trip_type: tripCategory },
    });
  };

  setIsPolicyModalOpen = (isOpen: boolean) => {
    this.setState({ isPolicyModalOpen: isOpen });
    trackEvent({
      eventName: VIEWED_POLICY_MODAL,
      properties: {
        type: POLICY_MODAL,
        entry_point: ModalScreens.FLIGHTS_SEARCH,
        funnel: "flights",
      },
    });
  };

  public render() {
    const {
      tripCategory,
      numTravelers,
      destination,
      origin,
      departureDate,
      returnDate,
      policies,
      sessionInfo,
    } = this.props;
    const {
      openPassengerCountPicker,
      hasMissingSearchInfoError,
      isPolicyModalOpen,
    } = this.state;
    const numTravelerString =
      numTravelers < 2
        ? `${numTravelers} Traveler`
        : `${numTravelers} Travelers`;

    return (
      <>
        <Box className="flight-search">
          <Box className="trip-type-and-traveler-pickers">
            <FlightCategoryToggle
              className={"flight-category-toggle b2b"}
              category={tripCategory as FlightCategoryToggleTripCategory}
              {...this.props}
            />
            {policies && (
              <>
                <PolicyModalButton
                  onClick={() => this.setIsPolicyModalOpen(true)}
                />
                <PolicyDetailsModal
                  policies={policies}
                  sessionInfo={sessionInfo}
                  isOpen={isPolicyModalOpen}
                  setIsOpen={this.setIsPolicyModalOpen}
                  productType="flight"
                />
              </>
            )}
          </Box>
          <Box className="location-pickers">
            <OriginAutocomplete
              className="origin-auto-complete b2b"
              customIcon={
                <Icon
                  name={IconName.B2BMapPin}
                  ariaLabel=""
                  aria-hidden={true}
                />
              }
              label="Where from?"
              value={this.props.origin}
              setValue={this.props.setOrigin}
              getOptionSelected={(
                option: ITripTerminus | null,
                value: ITripTerminus | null
              ) => {
                return (
                  !!value &&
                  !!option &&
                  value.id.code.code === option.id.code.code &&
                  value.label === option.label
                );
              }}
              hasMissingSearchInfoError={hasMissingSearchInfoError && !origin}
            />
            <DestinationAutocomplete
              className="destination-auto-complete b2b"
              customIcon={
                <Icon
                  name={IconName.B2BMapPin}
                  ariaLabel=""
                  aria-hidden={true}
                />
              }
              label="Where to?"
              value={this.props.destination}
              setValue={this.props.setDestination}
              getOptionSelected={(
                option: ITripTerminus | null,
                value: ITripTerminus | null
              ) => {
                return (
                  !!value &&
                  !!option &&
                  value.id.code.code === option.id.code.code &&
                  value.label === option.label
                );
              }}
              hasMissingSearchInfoError={
                hasMissingSearchInfoError && !destination
              }
            />
          </Box>
          <Box className="date-filter-row">
            <CalendarPickerButton
              classes={["date-pickers"]}
              saveDatesOnClose
              departureDate={this.props.departureDate}
              returnDate={this.props.returnDate}
              setDepartureDate={this.props.setDepartureDate}
              setReturnDate={this.props.setReturnDate}
              tripCategory={tripCategory}
              hasMissingSearchInfoError={
                hasMissingSearchInfoError &&
                !(tripCategory === TripCategory.ROUND_TRIP
                  ? departureDate && returnDate
                  : departureDate)
              }
            />
            <Box className="filter-pickers">
              <B2BButton
                aria-label={numTravelerString}
                className="num-travelers-input b2b"
                variant="traveler-selector"
                onClick={() => this.handleTravelersSelected()}
              >
                <Box className="num-traveler-content">
                  <Icon
                    aria-hidden={true}
                    className="icon-start"
                    name={IconName.B2BUser}
                    ariaLabel=""
                  />
                  <Box className="text">{numTravelerString}</Box>
                  <Icon
                    aria-hidden={true}
                    className="icon-end"
                    name={IconName.B2BEditPencil}
                    ariaLabel=""
                  />
                </Box>
              </B2BButton>
              <DesktopPopupModal
                open={openPassengerCountPicker}
                className="flight-desktop-passenger-count-picker-popup"
                contentClassName="desktop-passenger-count-picker-popup-container"
                onClose={() =>
                  this.setState({ openPassengerCountPicker: false })
                }
                invisibleBackdrop={false}
                headerElement={textConstants.EDIT_TRAVELERS_TITLE}
              >
                <PassengerCountPicker
                  minimumAdultsCount={1}
                  onClickApply={this.handleTravelersChanged}
                  className="b2b"
                  includeChildrenInMaxCount
                />
              </DesktopPopupModal>
              <FlightSearchButton
                className="flight-search-button b2b"
                message={constants.SEARCH}
                onClick={(history) => this.handleSearchClick(history)}
                enabled={true}
              />
            </Box>
          </Box>
          {hasMissingSearchInfoError && (
            <Box className="missing-info-search-error-container">
              <NotificationBanner
                className="missing-info-search-error-banner"
                label={constants.MISSING_INFO_SEARCH_ERROR}
                severity={BannerSeverity.ERROR}
                icon={<Icon name={IconName.WarningAlert} />}
              />
            </Box>
          )}
        </Box>
      </>
    );
  }
}
