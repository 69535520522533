import React, { ReactElement, useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@material-ui/core";
import { isEmpty } from "lodash";
import {
  FlightGridRow,
  FlightShopRow,
  formatInterval,
  getPricesWithComma,
  getTotalPriceText,
  B2BFlightCardV2,
  TriangleIcon,
  useDeviceTypes,
  getRewardText,
  getRewardsString,
  IconName,
  Icon,
  ButtonWrap,
  NotificationBanner,
  BannerSeverity,
  CorpPolicyBanner,
} from "halifax";
import clsx from "clsx";
import * as textConstants from "./textConstants";
import {
  AlgomerchTag,
  FlightRatingsEnum,
  Flights,
  Prices,
  getTags,
  IFlightGridFares,
  SelectedTravelOfferScreen,
  SELECTED_TRAVEL_OFFER,
  VIEWED_OFFER_FAQ,
  Slice,
  TagType,
  TravelWalletOffer,
  RewardsPrice,
  TravelWalletCredit,
  RewardsAccount,
  FetchCfarOfferResponseV2,
  FetchCfarOfferSuccessV2,
  CorpFlights,
  CorpFlightFare,
  CorporateTravel,
  PolicyViolation,
  PolicyDescriptorEntryPoints,
  UserFlightPreferencesPayload,
  AirlineOpenEnum,
  ENGAGED_OFFER_CTA,
  TripCategory,
} from "redmond";
import {
  getFlightPolicyLimit,
  getStopoverDurationForSegments,
  isCorpTenant,
  useShowPolicyBanner,
} from "@capone/common";
import { isInDisruptionProtectionRebookSelector } from "../../../../../reducer";
import { FareclassOptionFilter } from "../../../../../../search/reducer";
import { getAddedLegacyPrices } from "../../../../../../book/reducer/utils/pricingHelpers";
import { IFlightListData } from "../../component";
import { isCfarEligible } from "../../../../../../ancillary/utils/refundableFareHelpers";
import {
  AIR_CX_V3_1,
  AIR_CX_V3_1_VARIANTS,
  AVAILABLE,
  CONTROL,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  INTERNATIONAL_NGS_EXPERIMENT,
  TRAVEL_SALE,
  TRAVEL_SALE_ACTIVE,
  TRAVEL_SALE_VARIANTS,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  useExperiments,
  VI_SHOP_BANNERS_EXPERIMENT,
  VI_SHOP_BANNERS_GRAY_BANNER,
  VI_SHOP_BANNERS_NO_BANNER,
  VI_SHOP_BANNERS_VARIANTS,
} from "../../../../../../../context/experiments";
import { trackEvent } from "../../../../../../../api/v0/analytics/trackEvent";
import "./styles.scss";
import { ClientContext } from "../../../../../../../App";
import { config } from "../../../../../../../api/config";
import { isFlightRecommendedBasedOnPreferences } from "../../../../utils";
import { SELF_CHECK_BANNER_LEGEND_GRID_ROW_STRONG_SUFFIX } from "./textConstants";

export enum FlightCardType {
  content = "content",
  skeleton = "skeleton",
}

interface IFlightListInfoBaseProps {
  className?: string;
  type: FlightCardType;
}

export const isFlightMultiTicketType = ({
  flight,
  slices,
}: {
  flight?: IFlightListData;
  slices?: Record<string, Slice>;
}) => {
  if (flight && slices) {
    const { hasSelfTransferLayover } = slices[flight.slice];
    return hasSelfTransferLayover;
  }
  return false;
};

export interface IFlightListInfoContentProps extends IFlightListInfoBaseProps {
  selectedFare: any;
  slice: Slice;
  onClick?: (selectedFareClass: string) => void;
  onFareClick?: (fareId: string) => void;
  isExpanded?: boolean;
  type: FlightCardType.content;
  flights: Flights | CorpFlights;
  flight: IFlightListData;
  rewardsKey: string;
  fareClassFilter: FareclassOptionFilter;
  onAlgomerchClick: (val: string) => void;
  maxFlightPriceFilter: number;
  isRoundTrip: boolean;
  offersByTripId?: { [key: string]: TravelWalletOffer };
  showPriceAndTags?: boolean;
  useRowFlightListInfoOnly?: boolean;
  showRefundableFaresOnly?: boolean;
  cfarOffers?: {
    [key: string]: {
      [key: string]: FetchCfarOfferResponseV2;
    };
  };
  credit?: TravelWalletCredit;
  largestValueAccount?: RewardsAccount;
  onClickSelfCheckBanner?: () => void;
  isChatbotEnabled?: boolean;
  onOpenPolicyDescriptor?: (
    entryPoint: string,
    policyReasons: PolicyViolation[]
  ) => void;
  isOutgoing: boolean;
  isInPolicyFilter?: boolean;
  userHasSetFlightPreferences?: boolean;
  userFlightPreferences?: UserFlightPreferencesPayload;
  shouldApplyUserFlightPreferences?: boolean;
}

export interface IFlightListInfoSkeletonProps extends IFlightListInfoBaseProps {
  type: FlightCardType.skeleton;
}

interface GridFaresPerShelf {
  basic: number;
  standard: number;
  premium: number;
  enhanced: number;
  luxury: number;
}

const FARE_GRID_TAG_LIMIT = 2;

export const FlightListInfoContent = (props: IFlightListInfoContentProps) => {
  const {
    selectedFare,
    slice,
    isExpanded,
    flight,
    onClick,
    flights,
    rewardsKey,
    fareClassFilter,
    onAlgomerchClick,
    onFareClick,
    maxFlightPriceFilter,
    isRoundTrip,
    offersByTripId,
    showPriceAndTags = true,
    showRefundableFaresOnly,
    cfarOffers,
    useRowFlightListInfoOnly,
    credit,
    largestValueAccount,
    onClickSelfCheckBanner,
    isChatbotEnabled,
    onOpenPolicyDescriptor,
    isOutgoing,
    isInPolicyFilter,
    userFlightPreferences,
    userHasSetFlightPreferences,
    shouldApplyUserFlightPreferences,
  } = props;
  const isInDisruptionProtectionRebook = useSelector(
    isInDisruptionProtectionRebookSelector
  );

  const getFareSliceId = (fare: any) => {
    return fare?.fareSlice || fare.return;
  };

  const getFareId = (fare: any) => {
    return fare.example?.fare || fare.id;
  };

  const clientContext = useContext(ClientContext);
  const { isAgentPortal, policies, sessionInfo } = clientContext;

  const { matchesMobile, matchesDesktop, matchesLargeDesktop } =
    useDeviceTypes();

  const expState = useExperiments();
  const ngsEnabled = useMemo(
    () =>
      getExperimentVariant(
        expState.experiments,
        INTERNATIONAL_NGS_EXPERIMENT
      ) === AVAILABLE,
    [expState]
  );
  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = React.useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const travelSaleVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );

  const airCXV3Enabled = useMemo(
    () =>
      getExperimentVariantCustomVariants(
        expState.experiments,
        AIR_CX_V3_1,
        AIR_CX_V3_1_VARIANTS
      ) !== CONTROL,
    [expState]
  );

  const viShopBannerVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    VI_SHOP_BANNERS_EXPERIMENT,
    VI_SHOP_BANNERS_VARIANTS
  );

  const showEarnEnhancement =
    !!largestValueAccount && !!largestValueAccount.earn.flightsMultiplier;

  const matchesMediumDesktopOnly = matchesDesktop && !matchesLargeDesktop;

  const airline = flights.airlines[slice.marketingAirline];
  const airlineCode = airline ? airline.code : slice.marketingAirline;
  const airlineName = airline ? airline.displayName : slice.marketingAirline;

  function getFlightGridFares(flight: any, rewardsKey?: string) {
    const fares: IFlightGridFares = {
      basic: null,
      standard: null,
      premium: null,
      enhanced: null,
      luxury: null,
    };

    const faresPerShelf = flight.fares.reduce(
      (result: GridFaresPerShelf, fare: any) => {
        const fareSlice = flights.fareSlices[getFareSliceId(fare)];
        result[FlightRatingsEnum[fareSlice.fareShelf.value]] += 1;
        return result;
      },
      {
        basic: 0,
        standard: 0,
        premium: 0,
        enhanced: 0,
        luxury: 0,
      }
    );

    flight.fares.forEach((fare: any) => {
      // note: when it's in return flights, and it's only showing refundable fares, the FE should not display fares that are not offered with CFAR
      if (
        !showRefundableFaresOnly ||
        !cfarOffers ||
        isEmpty(cfarOffers) ||
        isCfarEligible(fare.tripId, fare.id, cfarOffers)
      ) {
        const fareSlice = flights.fareSlices[getFareSliceId(fare)];
        const tags = getTags(fareSlice.tags as any);
        const cfarOfferAmount = (
          cfarOffers?.[fare.tripId]?.[fare.id] as FetchCfarOfferSuccessV2
        )?.cfarOffer?.premiumPerPax;
        let reward: RewardsPrice | undefined;

        const gridFare = {
          fareId: getFareId(fare),
          tripId: fare.tripId,
          fareName: fareSlice.fareBrandName ?? "",
          // note: rawPrice is used in the price-range filtering logic, so it shouldn't include the CFAR price
          rawPrice: fare.amount?.fiat.value,
          tags,
        };

        // note: when it's showRefundableFaresOnly, the prices displayed on unexpanded drawers should be RF prices
        if (showRefundableFaresOnly && cfarOfferAmount) {
          const refundableFareAmount = getAddedLegacyPrices(
            fare.amount,
            cfarOfferAmount
          );
          reward = rewardsKey
            ? refundableFareAmount.rewards[rewardsKey]
            : undefined;
          gridFare["price"] = getTotalPriceText({
            price: refundableFareAmount.fiat,
          });
          gridFare["prices"] = refundableFareAmount;
        } else {
          reward = rewardsKey ? fare.amount?.rewards[rewardsKey] : undefined;
          gridFare["price"] = getTotalPriceText({ price: fare.amount?.fiat });
          gridFare["prices"] = fare.amount;
        }

        //  When there are multiple fares per shelf, set additional fares count to display
        if (faresPerShelf[FlightRatingsEnum[fareSlice.fareShelf.value]] > 1) {
          gridFare["additionalFares"] =
            faresPerShelf[FlightRatingsEnum[fareSlice.fareShelf.value]] - 1;
        }

        if (reward) {
          gridFare["reward"] = getRewardsString(reward, true);
        }

        const fareInFlights = flights.fares[getFareId(fare)];

        if (fareInFlights && "corporateTravel" in fareInFlights) {
          gridFare["corporateTravel"] = fareInFlights.corporateTravel;
        }

        // When there are multiple fares per shelf, display the lowest on the grid level
        fares[FlightRatingsEnum[fareSlice.fareShelf.value]] = getLowerGridFare(
          fares[FlightRatingsEnum[fareSlice.fareShelf.value]],
          gridFare
        );

        if (
          userHasSetFlightPreferences &&
          userFlightPreferences &&
          !shouldApplyUserFlightPreferences
        ) {
          gridFare["isRecommendedBasedOnPreferences"] =
            isFlightRecommendedBasedOnPreferences(
              fareSlice,
              userFlightPreferences,
              airlineCode as AirlineOpenEnum
            );
        }
      }
    });
    return fares;
  }

  function getLowerGridFare(existingFare: any, newFare: any) {
    const existingPrice = existingFare?.rawPrice;
    return existingPrice && existingPrice < newFare.rawPrice
      ? existingFare
      : newFare;
  }

  const gridFares = React.useMemo(
    () => getFlightGridFares(flight, rewardsKey),
    [flight, rewardsKey]
  );

  const offerToShow = React.useMemo(() => {
    const bestOffer =
      offersByTripId?.[
        flight.fares[0]?.example?.trip || flight.fares[0]?.tripId
      ];

    return bestOffer?.amount.amount === credit?.amount.amount
      ? undefined
      : bestOffer;
  }, [offersByTripId, credit]);

  const fareSlice = flights.fareSlices[getFareSliceId(selectedFare)];

  const showListView =
    (!slice?.domestic && !ngsEnabled) || useRowFlightListInfoOnly;

  const tags = [
    fareSlice?.tags.isBest ? AlgomerchTag.BestFlight : null,
    fareSlice?.tags.isCheapest ? AlgomerchTag.Cheapest : null,
    fareSlice?.tags.isBestQuality ? AlgomerchTag.BestQuality : null,
    fareSlice?.tags.isFastest ? AlgomerchTag.Fastest : null,
  ]
    .filter((t): t is AlgomerchTag => t !== null)
    .map((value) => ({ value, type: TagType.Algomerch }));

  const fareAmount: Prices | undefined = (() => {
    // case: displaying refundable fares (only)
    if (isRoundTrip && cfarOffers && showRefundableFaresOnly) {
      /*
                    on mobile, selectedFare is guranteed to be the one shown on the unexpanded flight drawer, because the RF filtering logic
                    makes sure to remove any itinerary that doesn't have its selectedFare offered with CFAR.
                  */
      if (matchesMobile) {
        const fareSlice = flights.fareSlices[getFareSliceId(selectedFare)];
        return gridFares[FlightRatingsEnum[fareSlice.fareShelf.value]]?.prices;
      }
      // on desktop, it picks the left most fare from the grids (that is offered with CFAR).
      else {
        const firstGridFare =
          gridFares.basic ??
          gridFares.standard ??
          gridFares.enhanced ??
          gridFares.premium ??
          gridFares.luxury;
        return firstGridFare?.prices;
      }
    }
    // case: default
    else {
      return selectedFare.amount as Prices;
    }
  })();
  const fareFiat = fareAmount?.fiat;
  const fareReward: RewardsPrice | undefined = fareAmount?.rewards[rewardsKey];
  const currentPriceText = fareFiat
    ? getPricesWithComma(
        getTotalPriceText({
          price: fareFiat,
        })
      )
    : "";
  const rewardsPriceText =
    !!rewardsKey && !!fareReward
      ? getRewardText({ reward: fareReward, round: true })
      : "";

  const trackViewedOfferFaq = () => {
    trackEvent({
      eventName: VIEWED_OFFER_FAQ,
      properties: {
        screen: SelectedTravelOfferScreen.FLIGHT_SHOP,
      },
    });
  };

  const trackOfferEngagementEvent = () => {
    trackEvent({
      eventName: ENGAGED_OFFER_CTA,
      properties: {
        location: SelectedTravelOfferScreen.FLIGHT_SHOP,
        entry_type: "tooltip",
        funnel: offerToShow?.funnels.join(","),
        offer_name: offerToShow?.trackingPropertiesV2?.properties?.offer_name,
      },
      encryptedProperties: [
        offerToShow?.trackingPropertiesV2?.encryptedProperties ?? "",
      ],
    });
  };

  const corporateTravel: CorporateTravel | undefined =
    selectedFare?.corporateTravel ??
    (flights.fares[selectedFare.example?.fare] as CorpFlightFare)
      ?.corporateTravel;

  const totalDurationWithoutStopover =
    (slice.totalDurationMinutes ?? 0) -
    getStopoverDurationForSegments(slice.segments);

  const policyLimit = getFlightPolicyLimit(
    policies?.flights,
    totalDurationWithoutStopover,
    isRoundTrip
  );

  const viNotificationBannerBox = (): ReactElement => {
    switch (viShopBannerVariant) {
      case VI_SHOP_BANNERS_GRAY_BANNER:
        return (
          <Box className="self-check-banner-grid-row">
            <NotificationBanner
              severity={BannerSeverity.NEUTRAL_INFO}
              icon={<Icon name={IconName.DiagonalGreyAirplane} />}
              label={textConstants.SELF_CHECK_BANNER_LEGEND_GRID_ROW_V2}
              strongLabelSuffix={
                SELF_CHECK_BANNER_LEGEND_GRID_ROW_STRONG_SUFFIX
              }
              cta={""}
              onClick={(e) => {
                e.stopPropagation();
                onClickSelfCheckBanner && onClickSelfCheckBanner();
              }}
            />
          </Box>
        );
      case VI_SHOP_BANNERS_NO_BANNER:
        return <Box />;
      default:
        return (
          <Box className="self-check-banner-grid-row">
            <NotificationBanner
              severity={BannerSeverity.WARNING}
              icon={<Icon name={IconName.BookTravel} />}
              label={textConstants.SELF_CHECK_BANNER_LEGEND_GRID_ROW}
              cta={textConstants.LEARN_MORE}
              onClick={(e) => {
                e.stopPropagation();
                onClickSelfCheckBanner && onClickSelfCheckBanner();
              }}
            />
          </Box>
        );
    }
  };

  const viShopWithoutBanner = viShopBannerVariant == VI_SHOP_BANNERS_NO_BANNER;

  // Small / Medium desktop view
  const renderRowDesktopFlightListInfo = () => {
    return (
      <div>
        {slice.hasSelfTransferLayover &&
          !viShopWithoutBanner &&
          viNotificationBannerBox()}
        <ButtonWrap
          className="flight-row-wrapper"
          aria-label="More flight details"
          onClick={handleOnClick}
          aria-expanded={isExpanded}
        >
          <FlightShopRow
            isMultiTicketType={slice.hasSelfTransferLayover}
            segments={slice.segments}
            isAgentPortal={isAgentPortal}
            airlineCode={airlineCode}
            airlineName={airlineName}
            arrivalTime={slice.arrival}
            className={clsx("small-flight-shop-row", "b2b")}
            currentPriceText={showPriceAndTags ? currentPriceText : undefined}
            rewardsPriceText={
              rewardsPriceText && showPriceAndTags
                ? rewardsPriceText
                : undefined
            }
            departureTime={slice.departure}
            destinationCode={slice.destination}
            duration={formatInterval(slice.totalDurationMinutes)}
            key={slice.id}
            layoverString={textConstants.getStopsString(slice.stops)}
            onClickTag={(label: string) => onAlgomerchClick(label)}
            originCode={slice.origin}
            tags={showPriceAndTags ? tags : undefined}
            tagsLimit={FARE_GRID_TAG_LIMIT}
            airlineProps={
              isInDisruptionProtectionRebook
                ? {
                    WN: {
                      hideFlightShopRowBanner: true,
                    },
                  }
                : undefined
            }
            isChatbotEnabled={isChatbotEnabled}
            isAirCXV3Experiment={airCXV3Enabled}
          />
          <TriangleIcon
            className={clsx("expand-flight-row-icon", {
              "is-expanded": isExpanded,
            })}
          />
        </ButtonWrap>
      </div>
    );
  };

  // Large desktop view
  const renderGridDesktopFlightListInfo = () => {
    return (
      <FlightGridRow
        key={slice.id}
        isMultiTicketType={slice.hasSelfTransferLayover}
        viNotificationBannerBox={viNotificationBannerBox}
        segments={slice.segments}
        isAgentPortal={isAgentPortal}
        tripType={isRoundTrip ? TripCategory.ROUND_TRIP : TripCategory.ONE_WAY}
        className={clsx("flight-grid-row", `row-${slice.id}`)}
        airlineCode={airlineCode}
        airlineName={airlineName}
        fareClassFilter={fareClassFilter}
        selectedMaxPrice={maxFlightPriceFilter}
        fares={gridFares}
        departureTime={slice.departure}
        arrivalTime={slice.arrival}
        originCode={slice.origin}
        destinationCode={slice.destination}
        duration={formatInterval(slice.totalDurationMinutes)}
        layoverString={textConstants.getStopsString(slice.stops)}
        onFareClick={(fareId) => {
          onFareClick && onFareClick(fareId);
        }}
        algomerchModalOpen={false}
        onAlgomerchInfoClick={(label: string) => onAlgomerchClick(label)}
        tagsLimit={FARE_GRID_TAG_LIMIT}
        isExpanded={isExpanded}
        fareCardClassName="b2b"
        type="content"
        bestOffer={offerToShow}
        showOffer={isTravelWalletOfferExperiment}
        onOfferTooltipSetModalOpen={(open) => {
          if (open) {
            trackEvent({
              eventName: SELECTED_TRAVEL_OFFER,
              properties: {
                screen: SelectedTravelOfferScreen.FLIGHT_SHOP,
                ...offersByTripId?.[
                  flight.fares[0]?.example?.trip || flight.fares[0]?.tripId
                ]?.trackingPropertiesV2?.properties,
              },
              encryptedProperties: [
                offersByTripId?.[
                  flight.fares[0]?.example?.trip || flight.fares[0]?.tripId
                ]?.trackingPropertiesV2?.encryptedProperties ?? "",
              ],
            });

            trackOfferEngagementEvent();
          }
        }}
        onFirstTravelOfferCardChange={trackViewedOfferFaq}
        earnString={
          showEarnEnhancement ? (
            <>
              <Icon name={IconName.StarIcon} />
              <Typography
                className="earn-tag-text"
                dangerouslySetInnerHTML={{
                  __html: textConstants.getEarnTagText(
                    largestValueAccount!.earn.flightsMultiplier
                  ),
                }}
              />
              {}
            </>
          ) : undefined
        }
        isChatbotEnabled={isChatbotEnabled}
        isTravelSale={travelSaleVariant === TRAVEL_SALE_ACTIVE}
        isAirCXV3Experiment={airCXV3Enabled}
        limit={policyLimit}
        {...(onOpenPolicyDescriptor &&
          corporateTravel && {
            onOpen: () =>
              onOpenPolicyDescriptor(
                isOutgoing
                  ? PolicyDescriptorEntryPoints.FLIGHTS_LIST
                  : PolicyDescriptorEntryPoints.FLIGHTS_LIST_RETURN,
                corporateTravel.policyCompliance.reasons
              ),
          })}
        isInPolicyFilter={isInPolicyFilter}
        hideIsInPolicy={!showPolicyBannerForNonDefaultPolicies}
      />
    );
  };

  const renderMobileFlightListInfo = () => {
    const airports = slice.segments
      .slice(0, slice.segments.length - 1)
      .map((s) => s.destination);

    return (
      <Box className="flight-card-wrapper">
        <CorpPolicyBanner
          variant="base"
          corporateTravel={showPolicyBanner ? corporateTravel : undefined}
          productType="flight"
          limit={policyLimit}
          {...(onOpenPolicyDescriptor &&
            corporateTravel && {
              onOpen: () =>
                onOpenPolicyDescriptor(
                  isOutgoing
                    ? PolicyDescriptorEntryPoints.FLIGHTS_LIST
                    : PolicyDescriptorEntryPoints.FLIGHTS_LIST_RETURN,
                  corporateTravel.policyCompliance.reasons
                ),
            })}
        />
        <B2BFlightCardV2
          isMultiTicketType={slice.hasSelfTransferLayover}
          viNotificationBannerBox={viNotificationBannerBox}
          segments={slice.segments}
          duration={formatInterval(slice.totalDurationMinutes)}
          currentPriceText={currentPriceText}
          rewardText={rewardsPriceText}
          originCode={slice.origin}
          destinationCode={slice.destination}
          departureTime={slice.departure}
          arrivalTime={slice.arrival}
          airports={airports}
          brandName={fareSlice?.fareBrandName ?? ""}
          primaryCarrier={airlineCode}
          isAgentPortal={isAgentPortal}
          airlineName={airlineName}
          tags={isCorpTenant(config.TENANT) ? [] : tags}
          bestOffer={offerToShow}
          showOffer={isTravelWalletOfferExperiment}
          showHeader={showPriceAndTags}
          onOfferTooltipSetModalOpen={(open) => {
            if (open) {
              trackEvent({
                eventName: SELECTED_TRAVEL_OFFER,
                properties: {
                  screen: SelectedTravelOfferScreen.FLIGHT_SHOP,
                  ...offersByTripId?.[
                    flight.fares[0]?.example?.trip || flight.fares[0]?.tripId
                  ]?.trackingPropertiesV2?.properties,
                },
                encryptedProperties: [
                  offersByTripId?.[
                    flight.fares[0]?.example?.trip || flight.fares[0]?.tripId
                  ]?.trackingPropertiesV2?.encryptedProperties ?? "",
                ],
              });

              trackOfferEngagementEvent();
            }
          }}
          onFirstTravelOfferCardChange={trackViewedOfferFaq}
          earnString={
            showEarnEnhancement ? (
              <>
                <Icon name={IconName.StarIcon} />
                <Typography
                  className="earn-tag-text"
                  dangerouslySetInnerHTML={{
                    __html: textConstants.getEarnTagText(
                      largestValueAccount!.earn.flightsMultiplier
                    ),
                  }}
                />
                {}
              </>
            ) : undefined
          }
          earnTagClassName="b2b"
          viewPricingPrompt={isChatbotEnabled}
          isTravelSale={travelSaleVariant === TRAVEL_SALE_ACTIVE}
          isAirCXV3Experiment={airCXV3Enabled}
          isRecommendedBasedOnPreferences={isFlightRecommendedBasedOnPreferences(
            fareSlice,
            userFlightPreferences,
            airlineCode as AirlineOpenEnum
          )}
        />
      </Box>
    );
  };

  const handleOnClick = () => {
    onClick && onClick(fareSlice.fareBrandName);
    if (!matchesLargeDesktop) {
      onFareClick && onFareClick(getFareId(selectedFare));
    }
  };

  const showPolicyBannerForNonDefaultPolicies = useShowPolicyBanner(
    policies,
    sessionInfo
  );

  const showPolicyBanner =
    (matchesMediumDesktopOnly || (matchesLargeDesktop && showListView)) &&
    showPolicyBannerForNonDefaultPolicies;

  return (
    <>
      <CorpPolicyBanner
        variant="descriptor"
        corporateTravel={showPolicyBanner ? corporateTravel : undefined}
        productType="flight"
        limit={policyLimit}
        {...(onOpenPolicyDescriptor &&
          corporateTravel && {
            onOpen: () =>
              onOpenPolicyDescriptor(
                isOutgoing
                  ? PolicyDescriptorEntryPoints.FLIGHTS_LIST
                  : PolicyDescriptorEntryPoints.FLIGHTS_LIST_RETURN,
                corporateTravel.policyCompliance.reasons
              ),
          })}
      />
      <Box
        className="flight-list-info-root"
        onClick={
          (matchesLargeDesktop && !showListView) || matchesMobile
            ? () => handleOnClick()
            : undefined
        }
      >
        {matchesLargeDesktop &&
          !showListView &&
          renderGridDesktopFlightListInfo()}
        {matchesLargeDesktop &&
          showListView &&
          renderRowDesktopFlightListInfo()}
        {matchesMediumDesktopOnly && renderRowDesktopFlightListInfo()}
        {matchesMobile && renderMobileFlightListInfo()}
      </Box>
    </>
  );
};

export type IFlightListInfoProps =
  | IFlightListInfoContentProps
  | IFlightListInfoSkeletonProps;

export const FlightListInfo = (props: any) => {
  switch (props.type) {
    case "content":
      return <FlightListInfoContent {...props} />;
    case "skeleton":
      return (
        <Box className={"flight-list-info-root"}>
          <FlightGridRow {...props} />
        </Box>
      );
    default:
      return <></>;
  }
};
