import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  NoResults,
  useDeviceTypes,
  B2BSpinner,
  ExpandableCard,
  LoadingIndicator,
} from "halifax";
import { Box, Button } from "@material-ui/core";
import {
  FareDetails,
  mapAlgomerchTexts,
  AlgomerchTag,
  VIEWED_FLIGHT_LIST,
  SELECTED_FLIGHT,
  VIEWED_SLICE,
  RewardsPrice,
  FiatPrice,
} from "redmond";
import { RouteComponentProps } from "react-router";
import InfiniteScroll from "react-infinite-scroll-component";
import clsx from "clsx";

import "./styles.scss";
import { FlightListInfo } from "./components/FlightListInfo";
import { FlightDetails } from "./components/FlightDetails";
import { MobileFlightDetailsModal } from "./components/MobileFlightDetailsModal";
import { FlightFindMoreResults } from "./components/FlightFindMoreResults";
import { PriceFreezeRefund } from "./components/PriceFreezeRefund";
import { MobileFilterFareDetails } from "./components/MobileFilterFareDetails";
import { FlightAlgomerchModal } from "../FlightAlgomerchModal";
import * as constants from "./constants";
import { FlightShopStep, ISelectedTrip } from "../../reducer";
import { FlightCardType } from "./components/FlightListInfo/component";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { FlightListConnectorProps } from "./container";
import { CHANGE_DATES } from "../../constants";
import {
  AVAILABLE,
  getExperimentVariant,
  INTERNATIONAL_NGS_EXPERIMENT,
  useExperiments,
} from "../../../../context/experiments";
import { ClientContext } from "../../../../App";
import { useShowPolicyBanner } from "@capone/common";

export interface IFlightListProps
  extends FlightListConnectorProps,
    RouteComponentProps {
  onCompleteFareSelect?: (selectedFare: {
    tripId: string;
    fareId: string;
  }) => void;
  isInPriceFreezePurchase?: boolean;
  isInSimilarFlights?: boolean;
  cheapestFrozenPrice?: { fiat: FiatPrice; rewards: RewardsPrice | undefined };
}

enum ModalTypes {
  AlgomerchModal,
  MobileFlightDetails,
}

type IOpenModal = ModalTypes | false;

const MOBILE_OFFSET_SCROLL = 300;
const DESKTOP_OFFSET_SCROLL = 250;

export const FlightList = ({
  isReturn,
  isOneWay,
  sortedFlightIds,
  tripSummariesLoading,
  hasTripSummariesError,
  tripDetailsLoading,
  fetchTripDetails,
  tripIdsByReturnSlice,
  setChosenOutgoingSlice,
  setChosenReturnSlice,
  setFlightShopProgress,
  flightShopProgress,
  hasAppliedFareClassFilter,
  hasAppliedNonFareclassFilter,
  setFlightNumberFilter,
  setAirportFilter,
  setPolicyFilter,
  selectedTrip,
  viewedForecastProperties,
  selectedOutgoingSliceProperties,
  selectedReturnSliceProperties,
  resetAll,
  setOpenFlightShopCalendarDesktop,
  flightInfoById,
  onCompleteFareSelect,
  isInPriceFreezePurchase,
  isInSimilarFlights,
  travelOfferProperties,
  getFlightFareDetailsByTripId,
  cfarDiscountProperties,
}: IFlightListProps) => {
  const { matchesMobile, matchesDesktop, matchesLargeDesktop } =
    useDeviceTypes();
  const expState = useExperiments();
  const ngsEnabled = useMemo(
    () =>
      getExperimentVariant(
        expState.experiments,
        INTERNATIONAL_NGS_EXPERIMENT
      ) === AVAILABLE,
    [expState]
  );
  const matchesMediumDesktopOnly = matchesDesktop && !matchesLargeDesktop;
  const disableDateChange = isInPriceFreezePurchase || isInSimilarFlights;
  const [flightsToShow, setFlightsToShow] = useState<string[]>([]);
  const [selectedFareId, setSelectedFareId] = useState("");
  const [expandedFlight, setExpandedFlight] = useState("");
  const [selectedTripId, setSelectedTripId] = useState("");
  const [selectedSliceId, setSelectedSliceId] = useState("");
  const [selectedFare, setSelectedFare] = useState<FareDetails | undefined>(
    undefined
  );

  const [openModal, setOpenModal] = React.useState<IOpenModal>(false);
  const [selectedAlgomerchTag, setSelectedAlgomerchTag] =
    React.useState<AlgomerchTag>(AlgomerchTag.Cheapest);

  const handleClickAlgomerchTag = (tagText: string) => {
    const allTags = Object.keys(AlgomerchTag);
    const selectedTag = allTags.find((tag) =>
      tagText.includes(mapAlgomerchTexts[tag])
    );

    setSelectedAlgomerchTag(
      (selectedTag as AlgomerchTag) ?? AlgomerchTag.Cheapest
    );
  };

  const onFlightRowFareClick = (fareId: string) => {
    setSelectedFareId(fareId);
  };

  const selectFare = (tripId: string, sliceId: string, fare?: FareDetails) => {
    setExpandedFlight("");
    if (isReturn) {
      setChosenReturnSlice({
        returnFareId: fare!!.id,
        returnSliceId: sliceId,
        returnFareRating: fare?.slices[1].fareShelf?.rating,
        tripId: tripIdsByReturnSlice ? tripIdsByReturnSlice[sliceId] : "",
      });
      trackEvent({
        eventName: SELECTED_FLIGHT,
        properties: {
          ...selectedReturnSliceProperties,
          ...travelOfferProperties?.properties,
          ...{
            rf_discount_original_premium:
              cfarDiscountProperties.original_premium,
            rf_discount_percentage: cfarDiscountProperties.discount_percentage,
          },
        },
        encryptedProperties: [
          ...(travelOfferProperties?.encryptedProperties || []),
        ],
      });
    } else {
      if (!isOneWay) {
        setFlightShopProgress(FlightShopStep.ChooseReturn);
      }
      setAirportFilter([]);
      setFlightNumberFilter([]);
      setPolicyFilter(false);
      const selectedOWRTrip = selectedTrip as ISelectedTrip;
      setChosenOutgoingSlice({
        outgoingFareId: fare!!.id,
        outgoingSliceId: sliceId,
        outgoingFareRating: undefined,
        tripId: tripId,
        // whenever selecting a different departure flight, reset return ids
        resetReturnIds:
          selectedOWRTrip?.outgoingFareId !== fare!!.id ||
          selectedOWRTrip?.outgoingSliceId !== sliceId,
      });
      resetAll();
      trackEvent({
        eventName: SELECTED_FLIGHT,
        properties: {
          ...selectedOutgoingSliceProperties,
          ...travelOfferProperties?.properties,
          ...{
            rf_discount_original_premium:
              cfarDiscountProperties.original_premium,
            rf_discount_percentage: cfarDiscountProperties.discount_percentage,
          },
        },
        encryptedProperties: [
          ...(travelOfferProperties?.encryptedProperties || []),
        ],
      });
    }
  };

  const mobileSelectFare = (
    tripId: string,
    sliceId: string,
    fare?: FareDetails
  ) => {
    setSelectedSliceId(sliceId);
    setSelectedTripId(tripId);
    setSelectedFare(fare);
    setOpenModal(ModalTypes.MobileFlightDetails);
  };

  const handleOnCompleteFareSelect = (tripId: string, fareId: string) => {
    if (onCompleteFareSelect && (isReturn || isOneWay)) {
      onCompleteFareSelect({ tripId, fareId });
    }
  };

  const onFareSelectClick = (
    tripId: string,
    sliceId: string,
    fare?: FareDetails
  ) => {
    if (matchesMobile) {
      mobileSelectFare(tripId, sliceId, fare);
    } else {
      selectFare(tripId, sliceId, fare);
      handleOnCompleteFareSelect(tripId, fare?.id ?? "");
    }
  };

  const onModalContinue = () => {
    selectFare(selectedTripId, selectedSliceId, selectedFare);
    handleOnCompleteFareSelect(selectedTripId, selectedFare?.id ?? "");
    setOpenModal(false);
  };

  const onExpandItem = (tripId: string) => {
    if (tripId === expandedFlight) {
      setExpandedFlight("");
    } else {
      /*
              note: in the similar-flights flow, fares in tripDetails will be filtered at the saga level; once the FE has
              added the similar-flights flow to FlightShopV2, this change will be irrelevant because V1 is getting removed
            */
      const filterFareDetail = isInSimilarFlights
        ? (fare: FareDetails): boolean => {
            return !!getFlightFareDetailsByTripId(tripId).find(
              (tripFare) => tripFare.fareId === fare.id
            );
          }
        : undefined;

      fetchTripDetails({ tripId, filterFareDetail });
      setExpandedFlight(tripId);

      const flightInfo = flightInfoById(tripId);
      trackEvent({
        eventName: VIEWED_SLICE,
        properties: {
          ...viewedForecastProperties,
          fare_class: flightInfo
            ? isReturn
              ? flightInfo?.tripFares[0]?.fareShelf?.returning?.shortBrandName
              : flightInfo?.tripFares[0]?.fareShelf?.outgoing?.shortBrandName
            : "",
        },
      });
    }
  };

  const setFetchMoreData = () => {
    const newPageSize = flightsToShow.length + constants.SHOW_MORE_NUM;
    return setTimeout(
      () => setFlightsToShow(sortedFlightIds.slice(0, newPageSize)),
      500
    );
  };

  useEffect(() => {
    if (!tripSummariesLoading && sortedFlightIds.length > 0) {
      trackEvent({
        eventName: VIEWED_FLIGHT_LIST,
        properties: {
          ...viewedForecastProperties,
          ...travelOfferProperties?.properties,
          price_freeze_flow: isInSimilarFlights,
        },
        encryptedProperties: [
          ...(travelOfferProperties?.encryptedProperties || []),
        ],
      });
    }
  }, [tripSummariesLoading]);

  useEffect(() => {
    if (flightShopProgress === FlightShopStep.ChooseReturn) {
      trackEvent({
        eventName: VIEWED_FLIGHT_LIST,
        properties: {
          ...viewedForecastProperties,
          ...travelOfferProperties?.properties,
          price_freeze_flow: isInSimilarFlights,
        },
        encryptedProperties: [
          ...(travelOfferProperties?.encryptedProperties || []),
        ],
      });
    }
  }, [flightShopProgress]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (sortedFlightIds.length > 0) {
      setFlightsToShow(
        sortedFlightIds.slice(0, constants.INITIAL_RESULT_SET_SIZE)
      );
    } else {
      setFlightsToShow([]);
    }
    return clearTimeout(setFetchMoreData());
  }, [sortedFlightIds]);

  const { policies, sessionInfo } = useContext(ClientContext);
  const showPolicyBanner = useShowPolicyBanner(policies, sessionInfo);

  const renderSkeletonFlights = () => {
    return (
      <ExpandableCard
        key={""}
        className={clsx("flight-list-item", "flight-row", "b2b")}
        isMobile={matchesMobile}
        expandedCardKey={expandedFlight}
        cardKey={""}
        handleCardKeyChange={() => onExpandItem("")}
        scrollExpandedIntoView={true}
        content={{
          title: (
            <FlightListInfo
              tripId={""}
              onFareClick={() => {}}
              type={FlightCardType.skeleton}
            />
          ),
          body: <></>,
        }}
      />
    );
  };
  const renderFlights = () => (
    <InfiniteScroll
      dataLength={flightsToShow.length}
      next={setFetchMoreData}
      hasMore={flightsToShow.length < sortedFlightIds.length}
      loader={
        <Box className="loading-flights">
          <B2BSpinner classes={["loading-flights-bunny"]} />
        </Box>
      }
    >
      {flightsToShow.map((tripId) => {
        const showListView = !flightInfoById(tripId)?.domestic && !ngsEnabled;
        return (
          <Box
            id={tripId}
            className={clsx(
              "flight-list-item",
              "flight-row",
              {
                "row-view-desktop":
                  matchesMediumDesktopOnly ||
                  (matchesLargeDesktop && showListView),
              },
              "b2b"
            )}
          >
            <FlightListInfo
              id={tripId}
              key={tripId}
              tripId={tripId}
              onFareClick={(fareId) => {
                onFlightRowFareClick(fareId);
              }}
              type={FlightCardType.content}
              isExpanded={tripId === expandedFlight}
              onClick={() => {
                onExpandItem(tripId);
                setTimeout(() => {
                  const OFFSET = matchesMobile
                    ? MOBILE_OFFSET_SCROLL
                    : DESKTOP_OFFSET_SCROLL;
                  const cardTop =
                    document?.getElementById(tripId)?.getBoundingClientRect()
                      .top || 0;
                  window.scrollBy({
                    top: (cardTop as number) - OFFSET,
                    behavior: matchesMobile ? "auto" : "smooth",
                  });
                }, 100);
              }}
              hideIsInPolicy={!showPolicyBanner}
            />
            {tripId === expandedFlight && !tripDetailsLoading && (
              <FlightDetails
                tripId={tripId}
                isOutgoing={!isReturn}
                selectedFareId={selectedFareId}
                onFareClick={(sliceId, fare) =>
                  onFareSelectClick(tripId, sliceId, fare)
                }
                onAlgomerchClick={(label: string) => {
                  handleClickAlgomerchTag(label);
                  setOpenModal(ModalTypes.AlgomerchModal);
                }}
              />
            )}
            {tripId === expandedFlight && tripDetailsLoading && (
              <LoadingIndicator
                className="flight-shop-details-loading-indicator"
                indicatorSize={"small"}
                indicator={B2BSpinner}
                message={constants.LOADING_FLIGHT_DETAILS_STRING}
              />
            )}
          </Box>
        );
      })}
    </InfiniteScroll>
  );

  const renderFlightShopFooter = () => {
    if (isInSimilarFlights) {
      return <PriceFreezeRefund />;
    } else {
      return <FlightFindMoreResults />;
    }
  };

  const renderNoFlightsMessaging = () => {
    let noFlightsString = constants.GENERIC_FLIGHTS_NOT_FOUND_SUBTITLE;

    if (hasAppliedFareClassFilter) {
      noFlightsString = constants.FARE_CLASS_FLIGHTS_NOT_FOUND_SUBTITLE;
    } else if (hasAppliedNonFareclassFilter) {
      noFlightsString = constants.FITLERED_FLIGHTS_NOT_FOUND_SUBTITLE;
    }

    return hasTripSummariesError ? (
      <Box className="no-results-container">
        <NoResults
          className="flight-list-no-results"
          title={constants.ERROR_TITLE}
          subtitle={constants.ERROR_SUBTITLE}
        />
        <button
          onClick={() => window.location.reload()}
          className={"reload-button"}
        >
          {constants.RELOAD}
        </button>
      </Box>
    ) : (
      <Box className="no-results-container">
        <NoResults
          className="flight-list-no-results"
          title={constants.FLIGHTS_NOT_FOUND_TITLE}
          subtitle={noFlightsString}
        />
        {!disableDateChange && (
          <Button
            onClick={() => setOpenFlightShopCalendarDesktop(true)}
            className={clsx("change-dates-button", "b2b")}
          >
            {CHANGE_DATES}
          </Button>
        )}
      </Box>
    );
  };

  return (
    <>
      <Box
        className={clsx(
          "flight-list",
          { "flight-list-skeleton": tripSummariesLoading },
          { mobile: matchesMobile }
        )}
      >
        <MobileFilterFareDetails />
        {!tripSummariesLoading ? (
          <>
            {flightsToShow.length > 0
              ? renderFlights()
              : renderNoFlightsMessaging()}
          </>
        ) : (
          renderSkeletonFlights()
        )}
      </Box>
      {!tripSummariesLoading &&
      sortedFlightIds.length === flightsToShow.length &&
      sortedFlightIds.length !== 0 &&
      (hasAppliedFareClassFilter ||
        hasAppliedNonFareclassFilter ||
        isInSimilarFlights)
        ? renderFlightShopFooter()
        : null}
      <FlightAlgomerchModal
        selectedCategory={selectedAlgomerchTag}
        setSelectedCategory={setSelectedAlgomerchTag}
        openModal={openModal === ModalTypes.AlgomerchModal}
        onClose={() => setOpenModal(false)}
      />
      <MobileFlightDetailsModal
        openModal={openModal === ModalTypes.MobileFlightDetails}
        onClose={() => setOpenModal(false)}
        departure={!isReturn}
        tripId={selectedTripId}
        fareDetails={selectedFare}
        onClick={onModalContinue}
      />
    </>
  );
};
