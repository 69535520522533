import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box, Dialog, Typography } from "@material-ui/core";
import {
  DesktopPopupModal,
  FlightConfirmation,
  Icon,
  IconName,
  formatInterval,
  useDeviceTypes,
  PriceDropProtection,
  MobilePriceDropProtectionImage,
  PriceDropProtectionImage,
  MobilePopoverCard,
  ActionLink,
  CloseButtonIcon,
  removeTimezone,
  AddOnEnum,
  AddOn,
  ButtonWrap,
  IFlightSummaryPanelProps,
  UserPreferencesBanner,
  BOOKING_SUCCESS_TITLE,
} from "halifax";
import {
  AgentFeeValue,
  Airport,
  PriceDropProtectionEnum,
  PriceDropViewedProperties,
  PRICE_DROP_VIEWED,
  VIEWED_PRICE_DROP_DETAILS,
  PRICE_DROP_PROTECTION_BODY,
  BOOKING_FEE_WAIVED,
  HotelEntryTypeEnum,
  CallState,
  CorpSessionInfo,
  ModalNames,
} from "redmond";
import dayjs from "dayjs";
import clsx from "clsx";
import { useSelector } from "react-redux";
import { useExperimentIsVariant } from "@capone/experiments";
import { isCorpTenant } from "@capone/common";
import { RouteComponentProps, Link } from "react-router-dom";

import { BookingSuccessModalConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import {
  HOTEL_PATH,
  PATH_CUSTOMER_PROFILE,
  PATH_HOME,
  PATH_TRAVEL_SALE,
  PATH_TRIPS,
} from "../../../../utils/urlPaths";
import { getPlusDays } from "../../../shop/reducer/utils";
import { PATH_PRICE_DROP_PROTECTION } from "../../../../../src/utils/urlPaths";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { ClientContext } from "../../../../App";
import { getCompleteBuyAirProperties } from "../../reducer";
import "./styles.scss";
import {
  BOOKING_CONFIRMATION_TITLE,
  PORTAL_TITLE,
} from "../../../../lang/textConstants";
import {
  AVAILABLE,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  TREES_MODAL_EXPERIMENT,
  useExperiments,
  TRAVEL_SALE_VARIANTS,
  TRAVEL_SALE,
  TRAVEL_SALE_ACTIVE,
  CUSTOMER_PROFILE_EXPERIMENT,
  CUSTOMER_PROFILE_VARIANTS,
  CONTROL,
  HOTEL_CROSS_SELL_V3_EXPERIMENT,
  HOTEL_CROSS_SELL_V3_VARIANTS,
} from "../../../../context/experiments";
import {
  isPriceDropCreditEnabledSelector,
  getPriceDropRefundTypeSelector,
} from "../../../ancillary/reducer/selectors";
import { FlightToHotelCrossSell } from "../../../cross-sell";
import { config } from "../../../../api/config";
import { getBannerText } from "./textConstants";
import { useExperimentsById } from "@capone/experiments";
import { updateUserSeenModal } from "b2b-base/src/api/v1/user/updateUserSeenModal";

export interface IBookingSuccessModalProps
  extends BookingSuccessModalConnectorProps,
    RouteComponentProps {
  isMultiCity: boolean;
}

export const BookingSuccessModal = ({
  itinerary,
  tripDetails,
  isRoundTrip,
  isMultiCity,
  history,
  redoSearch,
  earnString,
  airports,
  confirmationEmail: email = "",
  prediction,
  chargeAgentBookingFee,
  priceDropViewedProperties,
  lodgings,
  hasUserSetFlightPreferences,
  userFlightPreferencesCallState,
  potentialCrossSellOffers,
  searchLocationResult,
  fromDate,
  untilDate,
  nextPageToken,
  selectedTravelersList,
  fetchMoreCrossSellHotelAvailability,
  selectedTravelersIds,
  userPassengers,
}: IBookingSuccessModalProps) => {
  const clientContext = useContext(ClientContext);
  const { matchesMobile } = useDeviceTypes();
  const completeBuyAirProperties = useSelector(getCompleteBuyAirProperties);
  const [openFeeWaivedModal, setOpenFeeWaivedModal] = useState(false);
  const { isAgentPortal, sessionInfo } = clientContext;

  const expState = useExperiments();

  const treesModalExperiment = getExperimentVariant(
    expState.experiments,
    TREES_MODAL_EXPERIMENT
  );
  const isTreesModalExperiment = useMemo(
    () => treesModalExperiment === AVAILABLE,
    [treesModalExperiment]
  );

  const travelSalesEventVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    TRAVEL_SALE,
    TRAVEL_SALE_VARIANTS
  );

  const isCustomerProfileExperiment =
    getExperimentVariantCustomVariants(
      expState.experiments,
      CUSTOMER_PROFILE_EXPERIMENT,
      CUSTOMER_PROFILE_VARIANTS
    ) !== CONTROL;

  const isPriceDropCreditEnabled = useSelector(
    isPriceDropCreditEnabledSelector
  );

  const hotelCrossSellV3Variant = useMemo(
    () =>
      getExperimentVariantCustomVariants(
        expState.experiments,
        HOTEL_CROSS_SELL_V3_EXPERIMENT,
        HOTEL_CROSS_SELL_V3_VARIANTS
      ),
    [expState.experiments]
  );

  const isFlightCheckoutRedesignEnabled = useExperimentIsVariant(
    "corp-flight-checkout-redesign",
    AVAILABLE
  );

  const userRoles =
    sessionInfo && "corporateInfo" in sessionInfo
      ? sessionInfo.corporateInfo.role
      : [];
  const isAdmin = userRoles.includes("Admin");

  const enableMultiEmails =
    isFlightCheckoutRedesignEnabled && isCorpTenant(config.TENANT);

  const isNfuProductAwarenessEnabled =
    useExperimentsById("corp-nfu-inproduct-awareness")?.variant === "available";

  const isNfuProductAwarenessDebugEnabled =
    useExperimentsById("corp-nfu-inproduct-awareness")?.variant === "debug";

  const hasSeenNfuInfoModal =
    (sessionInfo as CorpSessionInfo).corporateInfo?.hasSeenModalMap?.[
      ModalNames.NFU_IN_PRODUCT_AWARENESS
    ] ?? false;

  const shouldShowNfuProductAwareness =
    (isAdmin && !hasSeenNfuInfoModal && isNfuProductAwarenessEnabled) ||
    isNfuProductAwarenessDebugEnabled;

  useEffect(() => {
    if (lodgings?.length && lodgings?.length < 8 && nextPageToken) {
      fetchMoreCrossSellHotelAvailability();
    }
  }, [lodgings, nextPageToken]);

  const getUserName = () => {
    const selectedPassenger = userPassengers.find(
      (user) => user.id === selectedTravelersIds[0]
    );
    return selectedPassenger
      ? `${selectedPassenger?.givenName} ${selectedPassenger?.surname}`
      : "your users";
  };

  const isHotelCrossSellV3Experiment = hotelCrossSellV3Variant !== CONTROL;

  const priceDropRefundType = useSelector(getPriceDropRefundTypeSelector);

  const hasCfarIncluded = !!itinerary?.tripAncillaries.cfarId;
  const hasDPIncluded =
    !!itinerary?.tripAncillaries.delayId ||
    !!itinerary?.tripAncillaries.missedConnectionId;

  const hasSeatsIncluded = !!(
    itinerary?.seats && itinerary.seats.seats.length > 0
  );

  // Virtual Interline Flights will offer only one type of fare the most basic is safe to only check the first index
  const isVITripSelected = tripDetails.fareDetails[0]?.slices.some((slice) =>
    slice.fareDetails.segments.some((segment) => segment.isSelfTransferLayover)
  );

  const handleAgentBookingFeeClick = (value: AgentFeeValue) => {
    if (value.addFee && sessionInfo?.userInfo?.firstName) {
      chargeAgentBookingFee(sessionInfo?.userInfo?.firstName);
    } else {
      setOpenFeeWaivedModal(true);
      trackEvent({
        eventName: BOOKING_FEE_WAIVED,
        properties: {
          ...completeBuyAirProperties,
          agent_booking_fee_amount_usd: 0,
          agent_locator:
            itinerary?.travelItinerary.locators?.agent.unscopedValue,
          reason: value.reason,
        },
      });
    }
  };

  const [priceDropPredictionModalOpen, setPriceDropPredictionModalOpen] =
    useState(false);
  const isPDPEligible =
    prediction?.priceDropProtection?.PriceDropProtection ===
    PriceDropProtectionEnum.IsEligible;

  const FeeWaivedModalContent = useMemo(
    () => (
      <>
        <Icon className="check-icon" name={IconName.CheckCircleFilled} />
        <Box>
          <Typography className="agent-fee-waived-modal-title">
            {textConstants.FEE_WAIVED_SUCCESS_TITLE}
          </Typography>
        </Box>
      </>
    ),
    []
  );

  /**
   * @description Closes the Agent Fee Waived modal
   */
  const closeFeeWaivedModal = () => {
    setOpenFeeWaivedModal(false);
  };

  useEffect(() => {
    document.title = BOOKING_CONFIRMATION_TITLE;
    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  useEffect(() => {
    if (isPDPEligible) {
      const properties: PriceDropViewedProperties = {
        ...priceDropViewedProperties,
        page: "flight_confirmation",
      };
      trackEvent({
        eventName: PRICE_DROP_VIEWED,
        properties,
      });
    }
  }, [isPDPEligible]);

  // TODO: since both 'tripDetails' and 'itinerary' are not preserved on refresh, it would cause undefined error;
  // discuss with PM regarding what the expected behaviour is (e.g.: handle it properly with error modals, or preserve those states).
  if (tripDetails === undefined || !itinerary) {
    history.push(PATH_HOME);
    return null;
  }

  const getMultiCityFlightDetails = (isMobile: boolean) => {
    return tripDetails.slices.map((slice, sliceIndex) => {
      const {
        originCode,
        originName,
        destinationCode,
        destinationName,
        arrivalTime,
        departureTime,
        segmentDetails,
        totalDurationMinutes,
        stops,
      } = slice;
      const { bold, standard } = textConstants.getReviewCardHeaderTextMultiCity(
        sliceIndex,
        removeTimezone(departureTime),
        airports[originCode] ? airports[originCode].cityName : originName,
        airports[destinationCode]
          ? airports[destinationCode].cityName
          : destinationName,
        originCode,
        destinationCode
      );

      return {
        airlineCode: segmentDetails[0].airlineCode,
        airline: segmentDetails[0].airlineName,
        departureDescriptionBold: bold,
        departureDescription: standard,
        formattedDepartureTime: dayjs(removeTimezone(departureTime)).format(
          "h:mm A"
        ),
        formattedArrivalTime: dayjs(removeTimezone(arrivalTime)).format(
          "h:mm A"
        ),
        destinationCode: destinationCode,
        duration: formatInterval(totalDurationMinutes || 0),
        stopString: textConstants.getStopsString(stops),
        plusDays: getPlusDays(slice),
        isMobile,
      } as IFlightSummaryPanelProps;
    });
  };
  const getSliceDetails = (
    isOutgoing: boolean,
    isMobile: boolean,
    airports: { [key: string]: Airport }
  ) => {
    const slice = tripDetails.slices.find((s) => s.outgoing === isOutgoing);
    if (!slice) {
      return undefined;
    }

    const { bold, standard } = textConstants.getReviewCardHeaderText(
      isOutgoing,
      airports[slice.destinationCode]
        ? airports[slice.destinationCode].cityName
        : slice.destinationName,
      removeTimezone(slice.departureTime)
    );

    return {
      airlineCode: slice.segmentDetails[0].airlineCode,
      airline: slice.segmentDetails[0].airlineName,
      departureDescriptionBold: bold,
      departureDescription: standard,
      formattedDepartureTime: dayjs(removeTimezone(slice.departureTime)).format(
        "h:mm A"
      ),
      formattedArrivalTime: dayjs(removeTimezone(slice.arrivalTime)).format(
        "h:mm A"
      ),
      destinationCode: slice.destinationCode,
      duration: formatInterval(slice.totalDurationMinutes ?? 0),
      stopString: textConstants.getStopsString(slice.stops),
      plusDays: getPlusDays(slice),
      isMobile,
    } as IFlightSummaryPanelProps;
  };

  const renderClosePDPButton = () => {
    return (
      <ActionLink
        className="price-drop-protection-close-button"
        onClick={() => setPriceDropPredictionModalOpen(false)}
        content={<CloseButtonIcon className="close-button-icon" />}
        label="Close"
        showTappableArea={true}
      />
    );
  };

  const getAddOns = () => {
    const addOns: AddOn[] = [];

    if (hasSeatsIncluded) {
      addOns.push({
        title: textConstants.SEATS_TITLE,
        subtitle: textConstants.SEATS_DESCRIPTION,
        type: AddOnEnum.Seats,
      });
    }

    if (hasCfarIncluded) {
      addOns.push({
        title: textConstants.CFAR_TITLE,
        component: (
          <Box className="cfar-subtitle-section">
            <Typography className="cfar-subtitle" variant="inherit">
              {textConstants.CFAR_DESCRIPTION_ONE}
              <ButtonWrap
                className="my-trips-link"
                aria-label={
                  textConstants.CFAR_DESCRIPTION_MY_TRIPS_LINK_ARIA_LABEL
                }
                onClick={() => {
                  history.push({
                    pathname: PATH_TRIPS,
                  });
                }}
              >
                {textConstants.CFAR_DESCRIPTION_MY_TRIPS_LINK}
              </ButtonWrap>
              {textConstants.CFAR_DESCRIPTION_TWO}
            </Typography>
          </Box>
        ),
        type: AddOnEnum.CFAR,
      });
    }

    if (hasDPIncluded) {
      /**
       * This is not an ideal fix.
       *
       * Missed Connection Guarantee (MCG) is included with any Virtual Interlined (VI) itinerary, and no Flight Disruption Assistance (FDA) product of any kind is currently offered for sale with these itineraries.
       *
       * While no such MCG policy is explicitly tendered during the booking process, the backend implicitly attaches one policy and returns it with a successful quote.
       *
       * In the future, it may be possible for a customer to purchase FDA with VI trips. At that time, it will be necessary for the backend to provide a way to distinguish between the two products (i.e., the included MCG that covers VI slices versus an elected MCG for the complete itinerary).
       *
       * As of implementation, there's no way infer this except by inspecting the itinerary itself.
       *
       * @todo The backend should deliver the ticket type with the MCG in the quote response.
       *
       * @see packages/cap1-components/src/PriceBreakdown/component.tsx
       * @see https://hopper-jira.atlassian.net/browse/COTA-895
       * @see https://hopper-jira.atlassian.net/browse/COTA-1055
       */
      if (isVITripSelected) {
        addOns.push({
          title: textConstants.MISSED_CONNECTION_REBOOKING_TITLE,
          subtitle: textConstants.MISSED_CONNECTION_REBOOKING_DESCRIPTION,
          type: AddOnEnum.DisruptionProtection,
        });
      } else {
        addOns.push({
          title: textConstants.DISRUPTION_PROTECTION_TITLE,
          subtitle: textConstants.DISRUPTION_PROTECTION_DESCRIPTION,
          type: AddOnEnum.DisruptionProtection,
        });
      }
    }

    return addOns;
  };

  const treesModalText = {
    treesModalHeader: textConstants.TREES_MODAL_HEADER,
    treesModalTitle: textConstants.TREES_MODAL_TITLE,
    treesModalSubtitle: textConstants.TREES_MODAL_SUBTITLE,
    treesModalImgLocation: textConstants.TREES_MODAL_IMG_LOCATION,
    treesModalLinkCopy: textConstants.TREES_MODAL_CTA_TEXT,
    treesModalBoldedLinkCopy: textConstants.TREES_BOLDED_MODAL_CTA_TEXT,
  };

  const showUserHotelPreferencesBanner =
    isCustomerProfileExperiment &&
    matchesMobile &&
    userFlightPreferencesCallState === CallState.Success &&
    !hasUserSetFlightPreferences;

  return (
    <Box
      className={clsx("flight-booking-success-container", {
        mobile: matchesMobile,
        "cross-sell": lodgings?.length,
      })}
    >
      <FlightConfirmation
        isAgentPortal={isAgentPortal}
        agentFeeOnClick={handleAgentBookingFeeClick}
        bannerCopy={
          isMultiCity
            ? textConstants.MULTI_CITY_BANNER_COPY
            : textConstants.BANNER_COPY
        }
        title={BOOKING_SUCCESS_TITLE(
          enableMultiEmails,
          selectedTravelersList,
          email
        )}
        subtitle={textConstants.SUBTITLE(
          // note: for Capital One, an “H-” is hard coded in front of the code string
          `H-${itinerary.travelItinerary.locators?.agent.unscopedValue}` ||
            itinerary.id
        )}
        nextHeader={textConstants.WHATS_NEXT_HEADER}
        infoCardTitles={{
          hotelsTitle: textConstants.HOTELS_TITLE,
          hotelsDescription: textConstants.HOTELS_DESCRIPTION,
          carsTitle: textConstants.CARS_TITLE,
          carsDescription: textConstants.CARS_DESCRIPTION,
          addOnsTitle: textConstants.ADD_ONS_TITLE,
        }}
        // TODO: sliceDetails should be received from the PricedItinerary model
        multiCitySlices={
          isMultiCity ? getMultiCityFlightDetails(matchesMobile) : undefined
        }
        outgoing={getSliceDetails(true, matchesMobile, airports)}
        returning={
          isRoundTrip
            ? getSliceDetails(false, matchesMobile, airports)
            : undefined
        }
        onHotelsClick={() => {
          history.push("/hotels");
          redoSearch();
        }}
        onCarsClick={() => {
          history.push("/cars");
          redoSearch();
        }}
        isMobile={matchesMobile}
        earnString={earnString}
        pdpButtonProps={{
          onPDPClick: () => {
            isPDPEligible && setPriceDropPredictionModalOpen(true);
            trackEvent({
              eventName: VIEWED_PRICE_DROP_DETAILS,
              properties: {
                page: "flight_confirmation",
                refund_type: priceDropRefundType,
              },
            });
          },
          isPDPEligible,
          pdpButtonAriaLabelledBy:
            "booking-success-modal-price-drop-protection-popup",
        }}
        monitoringDuration={
          prediction
            ? textConstants.getMonitoringDaysCount(prediction)
            : undefined
        }
        addOns={getAddOns()}
        displayTrees={isTreesModalExperiment}
        treesModalText={treesModalText}
        showWhatIsNext={!lodgings?.length}
        travelSalesBannerProps={
          travelSalesEventVariant === TRAVEL_SALE_ACTIVE
            ? {
                subtitle: textConstants.TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE,
                ctaText: textConstants.TRAVEL_SALES_EVENT_ACTIVE_CTA,
                ctaOnClick: () => {
                  const path = `${PATH_TRAVEL_SALE}?entryType=air_confirmation`;
                  matchesMobile
                    ? history.push(path)
                    : window.open(path, "_blank");
                },
              }
            : undefined
        }
        isProdEnv={window.__mclean_env__.ENV === "production"}
        tenant={config.TENANT}
        hotelCrossSellV3Props={
          isHotelCrossSellV3Experiment &&
          !!lodgings?.length &&
          fromDate &&
          untilDate &&
          potentialCrossSellOffers.length > 0
            ? {
                isHotelCrossSellV3Enabled: isHotelCrossSellV3Experiment,
                hotelCrossSellComponent: (
                  <FlightToHotelCrossSell
                    isMobile={matchesMobile}
                    entryPoint={HotelEntryTypeEnum.FLIGHTS_CHECKOUT}
                    offerBannerText={getBannerText(
                      searchLocationResult,
                      fromDate,
                      untilDate,
                      matchesMobile,
                      potentialCrossSellOffers[0].confirmationPageDescription
                    )}
                    className="v3"
                  />
                ),
                ctaText: "Search hotels",
                onCTAClick: () => {
                  history.push(HOTEL_PATH);
                },
                offerText: `${potentialCrossSellOffers[0].confirmationPageDescription} now`,
                offerPillText: potentialCrossSellOffers[0].subDescription,
              }
            : undefined
        }
        shouldShowNfuProductAwareness={shouldShowNfuProductAwareness}
        travelerName={getUserName()}
        handleSeenNfuInfoModal={updateUserSeenModal}
        trackEvent={trackEvent}
      />
      {!!lodgings?.length &&
        (!isHotelCrossSellV3Experiment ||
          potentialCrossSellOffers.length === 0) && (
          <FlightToHotelCrossSell
            isMobile={matchesMobile}
            entryPoint={HotelEntryTypeEnum.FLIGHTS_CHECKOUT}
          />
        )}
      {prediction && isPDPEligible && matchesMobile ? (
        <>
          <MobilePopoverCard
            role="tooltip"
            id="booking-success-modal-price-drop-protection-popup"
            topRightButton={renderClosePDPButton()}
            open={priceDropPredictionModalOpen}
            className="mobile-price-drop-protection-popup"
            contentClassName="mobile-flight-price-drop-protection-content-wrapper"
            onClose={() => setPriceDropPredictionModalOpen(false)}
          >
            <PriceDropProtection
              className="price-drop-protection-card"
              image={MobilePriceDropProtectionImage}
              title={textConstants.PDP_TITLE}
              subtitle={textConstants.getSubtitle(
                prediction,
                isPriceDropCreditEnabled
              )}
              header={textConstants.PDP_HEADER}
              ctaText={textConstants.PDP_CTA_TEXT}
              isMobile={true}
              mobileTermsConditionCopy={PRICE_DROP_PROTECTION_BODY}
            />
          </MobilePopoverCard>
        </>
      ) : (
        prediction &&
        isPDPEligible && (
          <>
            <Dialog
              role="tooltip"
              id="booking-success-modal-price-drop-protection-popup"
              open={priceDropPredictionModalOpen}
              className="price-drop-protection-popup"
              /** TransitionProps fixes `role` issue bug in MUIv4 - https://github.com/mui/material-ui/issues/18935  */
              TransitionProps={{ role: "none" } as never}
              PaperProps={{
                /* eslint-disable */
                // @ts-ignore: Fix the type definition of PaperProps to include tabIndex.
                tabIndex: 0,
                /* eslint-enable */
              }}
              onClose={() => setPriceDropPredictionModalOpen(false)}
            >
              <Box className="price-drop-protection-wrapper">
                <PriceDropProtection
                  className="price-drop-protection-card"
                  image={PriceDropProtectionImage}
                  title={textConstants.PDP_TITLE}
                  onClick={() =>
                    window.open(`${PATH_PRICE_DROP_PROTECTION} `, "_blank")
                  }
                  onClose={() => setPriceDropPredictionModalOpen(false)}
                  subtitle={textConstants.getSubtitle(
                    prediction,
                    isPriceDropCreditEnabled
                  )}
                  header={textConstants.PDP_HEADER}
                  ctaText={textConstants.PDP_CTA_TEXT}
                />
              </Box>
            </Dialog>
          </>
        )
      )}
      {openFeeWaivedModal && (
        <Box className="agent-fee-waived-container">
          {matchesMobile ? (
            <MobilePopoverCard
              centered
              className="agent-fee-waived-popup-mobile"
              contentClassName="agent-fee-waived-wrapper"
              onClose={closeFeeWaivedModal}
              open={openFeeWaivedModal}
              topRightButton={
                <ActionLink content={<CloseButtonIcon />} label="Close" />
              }
            >
              {FeeWaivedModalContent}
            </MobilePopoverCard>
          ) : (
            <DesktopPopupModal
              className="agent-fee-waived-popup-desktop"
              open={openFeeWaivedModal}
              onClose={closeFeeWaivedModal}
            >
              {FeeWaivedModalContent}
            </DesktopPopupModal>
          )}
        </Box>
      )}
      {showUserHotelPreferencesBanner && (
        <UserPreferencesBanner
          type="flight"
          variant="fixed"
          isMobile
          userPreferencesCallState={CallState.Success}
          userHasSetPreferences={false}
          shouldApplyUserPreferences={false}
          setShouldApplyUserPreferences={() => {}}
          bannerProfileCTA={
            <Link
              to={`${PATH_CUSTOMER_PROFILE}?section=flight-preferences`}
              className="profile-cta"
            >
              Add travel preferences
            </Link>
          }
          modalProfileCTA={
            <Link
              to={`${PATH_CUSTOMER_PROFILE}?section=flight-preferences`}
              className="info-modal-primary-cta"
            >
              Add travel preferences
            </Link>
          }
        />
      )}
    </Box>
  );
};
