import React, { useContext } from "react";
import { FlightDetailsCard, useDeviceTypes, MixedCabinToolTip } from "halifax";
import { FareDetails } from "redmond";
import {
  COMBINATION_FLIGHT_TOOLTIP,
  COMBINATION_FLIGHT_WARNING,
  getReviewCardHeader,
} from "../../../FlightShopReviewItinerary/constants";
import * as textConstants from "../../../../../search/components/FlightSearchFilter/components/FareclassOptionDetails/textConstants";
import { getPlusDays } from "../../../../reducer/utils";
import { FlightDetailsConnectorProps } from "./container";
import { getSliceIndex } from "../../../../../../utils/flights";
import { Typography } from "@material-ui/core";
import { getIsMixedClass } from "../../../../constants";
import { ClientContext } from "../../../../../../App";
import { useShowPolicyBanner } from "@capone/common";

interface IFlightDetailsProps extends FlightDetailsConnectorProps {
  isOutgoing: boolean;
  selectedFareId: string;
  onFareClick: (sliceId: string, fare?: FareDetails) => void;
  onAlgomerchClick?: (label: string) => void;
}

enum ratings {
  BASIC,
  STANDARD,
  ENHANCED,
  PREMIUM,
  LUXURY,
}

const getEmptyRestrictionsText = (fareRating: number | undefined) => {
  switch (fareRating) {
    case ratings.BASIC:
      return textConstants.BASIC_DETAILS_TEXT;
    case ratings.STANDARD:
      return textConstants.STANDARD_DETAILS_TEXT;
    case ratings.ENHANCED:
      return textConstants.ENHANCED_DETAILS_TEXT;
    case ratings.PREMIUM:
      return textConstants.PREMIUM_DETAILS_TEXT;
    case ratings.LUXURY:
      return textConstants.LUXURY_DETAILS_TEXT;
    default:
      return "";
  }
};

export const FlightDetails = ({
  tripDetails,
  outgoingFareRating,
  isOutgoing,
  selectedFareId,
  onFareClick,
  onAlgomerchClick,
  rewardsKey,
  departureDate,
  returnDate,
  airports,
  isSpiritOrFrontierAirlinesSelected,
}: IFlightDetailsProps) => {
  const { matchesMobile } = useDeviceTypes();
  const [isMixedCabinClass, setIsMixedCabinClass] = React.useState(false);
  const [fareDetails, setFareDetails] = React.useState<FareDetails | undefined>(
    undefined
  );

  React.useEffect(() => {
    if (selectedFareId) {
      setFareDetails(
        tripDetails.fareDetails.find((f) => f.id === selectedFareId)
      );
    }
  }, [selectedFareId]);

  React.useEffect(() => {
    if (fareDetails) {
      isOutgoing
        ? setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[0]))
        : setIsMixedCabinClass(getIsMixedClass(fareDetails.slices[1]));
    }
  }, [fareDetails]);

  if (!tripDetails) return null;
  const slice = isOutgoing ? tripDetails.slices[0] : tripDetails.slices[1];
  const hackerFareNotice = tripDetails.fareDetails.map((fare) => {
    if (fare.multiTicket) {
      return {
        id: fare.id,
        message: COMBINATION_FLIGHT_WARNING,
        tooltipCopy: COMBINATION_FLIGHT_TOOLTIP,
      };
    } else {
      return null;
    }
  });

  const renderHeader = (header: string) => {
    const [fromHeader, dateHeader] = header.split(":");
    return (
      <Typography variant="body1" className="flight-details-header">
        <span className="from">{fromHeader}</span>
        <span className="date">{dateHeader}</span>
        {isMixedCabinClass && <MixedCabinToolTip />}
      </Typography>
    );
  };

  const firstSliceIndex = getSliceIndex(true, tripDetails);
  const secondSliceIndex = getSliceIndex(false, tripDetails);
  const outboundHeader =
    departureDate &&
    getReviewCardHeader(
      true,
      airports[tripDetails.slices[firstSliceIndex].destinationCode]
        ? airports[tripDetails.slices[firstSliceIndex].destinationCode].cityName
        : tripDetails.slices[firstSliceIndex].destinationName,
      departureDate,
      true
    );

  const returnHeader =
    returnDate &&
    getReviewCardHeader(
      false,
      airports[tripDetails.slices[secondSliceIndex].destinationCode]
        ? airports[tripDetails.slices[secondSliceIndex].destinationCode]
            .cityName
        : tripDetails.slices[secondSliceIndex].destinationName,
      returnDate,
      true
    );

  const { policies, sessionInfo } = useContext(ClientContext);
  const showPolicyBanner = useShowPolicyBanner(policies, sessionInfo);

  return (
    <FlightDetailsCard
      className={"b2b"}
      selectedFareId={selectedFareId}
      tripDetails={tripDetails}
      outgoingFareRating={isOutgoing ? undefined : outgoingFareRating}
      isOutgoing={isOutgoing}
      getEmptyRestrictionsText={getEmptyRestrictionsText}
      onFareClick={(fareId: string) =>
        onFareClick(
          slice.id,
          tripDetails.fareDetails.find((f) => f.id === fareId)
        )
      }
      fareNotice={hackerFareNotice}
      onAlgomerchClick={onAlgomerchClick}
      rewardsKey={rewardsKey}
      isMobile={matchesMobile}
      plusDays={getPlusDays(slice!)}
      header={
        isOutgoing
          ? outboundHeader
            ? renderHeader(outboundHeader)
            : undefined
          : returnHeader
          ? renderHeader(returnHeader)
          : undefined
      }
      isMixedCabinClass={isMixedCabinClass}
      isRefundableFaresEnabled={false}
      sortFares="total"
      isSpiritOrFrontierAirlinesSelected={isSpiritOrFrontierAirlinesSelected}
      hideIsInPolicy={!showPolicyBanner}
    />
  );
};
