import React, { ReactElement, useContext, useEffect, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  DesktopPopupModal,
  Icon,
  IconName,
  B2BButton,
  NotificationBanner,
  BannerSeverity,
  FlightCategoryRadio,
  PassengerCountPickerType,
  PolicyDetailsModal,
  PolicyModalButton,
  ButtonWrap,
} from "halifax";
import {
  CHANGED_PAX_COUNT,
  CHANGED_TRIP_TYPE,
  TripCategory,
  IPassengerCounts,
  ITripTerminus,
  RecentFlightSearch,
  SliceStopCountFilter,
  ModalScreens,
  POLICY_MODAL,
  VIEWED_POLICY_MODAL,
  PackagesEntryTypeEnum,
} from "redmond";
import "./styles.scss";
import { CalendarPickerButton } from "./components";
import { FlightSearchButton } from "../SearchButton";
import { PassengerCountPicker } from "./components/PassengerCountPicker";
import * as textConstants from "./textConstants";
import { FlightSearchControlV2ConnectorProps } from "./container";
import { RouteComponentProps, useHistory } from "react-router";
import * as H from "history";
import * as constants from "./constants";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { OriginDestinationSearch } from "./components/OriginDestinationSearch";
import { MulticityFlightSearchRouteList } from "../MulticityFlightSearchRouteList";
import dayjs from "dayjs";
import { FareclassOptionSelection } from "../FlightShopSearchControlV2/components/FlightShopSearchFilter/components";
import clsx from "clsx";
import { NonStopToggle } from "./components/NonstopToggle";
import { config } from "../../../../api/config";
import { useExperimentIsVariant } from "@capone/experiments";
import { ClientContext } from "../../../../App";
import {
  INFO_NEW_PACKAGES_CTA_LINK,
  INFO_NEW_PACKAGES_CTA_TEXT,
} from "../../constants";
import {
  getExperimentVariantCustomVariants,
  NOT_SELF_FUNDED,
  PACKAGES_EXPERIMENT,
  PACKAGES_EXPERIMENT_VARIANTS,
  SELF_FUNDED,
  useExperiments,
} from "../../../../context/experiments";
import { generatePackagesCTAUrl } from "../../../../utils/urlHelpers";

export interface IFlightSearchControlV2Props
  extends FlightSearchControlV2ConnectorProps,
    RouteComponentProps {
  recentSearches?: RecentFlightSearch[];
  onRecentSearchClick?: (search: RecentFlightSearch) => void;
  showFareClassFilter?: boolean;
  showNonStopToggle?: boolean;
}

export const FlightSearchControlV2 = ({
  tripCategory,
  setTripCategory,
  origin,
  setOrigin,
  destination,
  setDestination,
  departureDate,
  setDepartureDate,
  returnDate,
  setReturnDate,
  multicityRoutes,
  numTravelers,
  setCalendar,
  fetchDepartureCalendar,
  populateFlightShopQueryParams,
  resetFilters,
  airEntryProperties,
  isMultiCityEnabled,
  setMulticityDepartureDate,
  setMulticityDestination,
  setMulticityOrigin,
  recentSearches,
  onRecentSearchClick,
  fareclassOptionFilter,
  setFareclassOptionFilter,
  hasSetFareclassFilter,
  showFareClassFilter,
  showNonStopToggle,
  stopsOption,
  setStopsOption,
  isCustomerProfileExperiment,
  shouldApplyUserFlightPreferences,
  userFlightPreferences,
  adultsCount,
  childrenCount,
  infantsOnLapCount,
  infantsInSeatCount,
}: IFlightSearchControlV2Props): ReactElement => {
  const [openPassengerCountPicker, setOpenPassengerCountPicker] =
    useState(false);
  const [hasMissingSearchInfoError, setHasMissingSearchInfoError] =
    useState(false);
  const [
    hasMissingMulticitySearchInfoError,
    setHasMissingMulticitySearchInfoError,
  ] = useState(false);
  const [isPolicyModalOpen, setIsPolicyModalOpen] = React.useState(false);

  const expState = useExperiments();
  const packagesExperimentVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    PACKAGES_EXPERIMENT,
    PACKAGES_EXPERIMENT_VARIANTS
  );

  const isPackagesExperiment = [SELF_FUNDED, NOT_SELF_FUNDED].includes(
    packagesExperimentVariant
  );

  const { policies, sessionInfo } = useContext(ClientContext);

  const history = useHistory();

  const isPolicyDescriptorsEnabled = useExperimentIsVariant(
    "corp-admin-policy-descriptors",
    "available"
  );

  const isMultiCitySelected = tripCategory === TripCategory.MULTI_CITY;
  const isAllMulticityFieldsPopulated = multicityRoutes.every((r) =>
    Object.values(r).every((field) => field !== null)
  );

  useEffect(() => {
    setCalendar();
  }, [destination, origin, tripCategory, multicityRoutes]);

  // if MC values empty, populate the MC flights based on OW/RT origin, destination, departureDate, returnDate values
  const mcRoute1 = multicityRoutes[0];
  const mcRoute2 = multicityRoutes[1];

  useEffect(() => {
    if (!mcRoute1?.origin) setMulticityOrigin(origin, 0);
    if (!mcRoute2?.destination) setMulticityDestination(origin, 1);
  }, [origin]);

  useEffect(() => {
    if (!mcRoute1?.destination) setMulticityDestination(destination, 0);
    if (!mcRoute2?.origin) setMulticityOrigin(destination, 1);
  }, [destination]);

  useEffect(() => {
    if (departureDate && !mcRoute1?.departureDate)
      setMulticityDepartureDate(departureDate, 0);
  }, [departureDate]);

  useEffect(() => {
    if (returnDate && !mcRoute2?.departureDate) {
      setMulticityDepartureDate(returnDate, 1);
    }
  }, [returnDate]);

  // fill empty route origins with previous flight destination on multicity routes change
  useEffect(() => {
    if (mcRoute1?.departureDate && !mcRoute2?.departureDate) {
      const nextDay = dayjs(mcRoute1?.departureDate)
        .add(dayjs.duration({ days: 1 }))
        .toDate();
      setMulticityDepartureDate(nextDay, 1);
    }
    for (let i = 0; i < multicityRoutes.length - 1; i++) {
      const currentRoute = multicityRoutes[i];
      const nextRoute = multicityRoutes[i + 1];
      if (currentRoute?.destination && !nextRoute?.origin)
        setMulticityOrigin(currentRoute?.destination, i + 1);
    }
  }, [multicityRoutes]);

  useEffect(() => {
    if (
      !isMultiCitySelected ||
      (isMultiCitySelected && isAllMulticityFieldsPopulated)
    ) {
      setHasMissingMulticitySearchInfoError(false);
    }
  }, [multicityRoutes, tripCategory]);

  useEffect(() => {
    if (
      isMultiCitySelected ||
      (!!destination &&
        !!origin &&
        !!departureDate &&
        (tripCategory === TripCategory.ROUND_TRIP ? !!returnDate : true))
    ) {
      setHasMissingSearchInfoError(false);
    }
  }, [destination, origin, departureDate, tripCategory, returnDate]);

  useEffect(() => {
    const listener = () => resetFilters(true, true, true);
    addEventListener("clicked_recent_flight_search_card", listener);

    return () => {
      removeEventListener("clicked_recent_flight_search_card", listener);
    };
  }, []);

  const onShowPolicyDetailsModal = () => {
    setIsPolicyModalOpen(true);
    trackEvent({
      eventName: VIEWED_POLICY_MODAL,
      properties: {
        type: POLICY_MODAL,
        entry_point: ModalScreens.FLIGHTS_SEARCH,
        funnel: "flights",
      },
    });
  };

  const handleSetTripCategory = (tripCategory: TripCategory) => {
    setTripCategory(tripCategory);
    trackEvent({
      eventName: CHANGED_TRIP_TYPE,
      properties: { ...airEntryProperties, trip_type: tripCategory },
    });
  };

  const handleTravelersChanged = (counts: PassengerCountPickerType) => {
    setOpenPassengerCountPicker(false);

    const {
      adultsCount,
      childrenCount,
      infantsInSeatCount,
      infantsOnLapCount,
    } = counts as IPassengerCounts;

    trackEvent({
      eventName: CHANGED_PAX_COUNT,
      properties: {
        ...airEntryProperties,
        pax_total:
          adultsCount + childrenCount + infantsInSeatCount + infantsOnLapCount,
        trip_type: tripCategory,
      },
    });
  };

  const populateParamsAndResetFilters = (history: H.History) => {
    populateFlightShopQueryParams({
      history,
      useHistoryPush: true,
      forceQueryUpdate: false,
    });
    if (
      isCustomerProfileExperiment &&
      shouldApplyUserFlightPreferences &&
      userFlightPreferences
    ) {
      resetFilters(
        !userFlightPreferences.nonStopOnly,
        !userFlightPreferences.airlines.length,
        !Object.values(userFlightPreferences.fareClasses).some(
          (applied) => applied
        )
      );
    } else {
      resetFilters(!showNonStopToggle, true, true);
    }
  };

  const handleSearchClick = (history: H.History) => {
    if (
      !!destination &&
      !!origin &&
      !!departureDate &&
      (tripCategory === TripCategory.ROUND_TRIP ? !!returnDate : true)
    ) {
      populateParamsAndResetFilters(history);
    } else {
      setHasMissingSearchInfoError(true);
    }
  };

  const handleMulticitySearchClick = (history: H.History) => {
    if (isAllMulticityFieldsPopulated) {
      populateParamsAndResetFilters(history);
    } else {
      setHasMissingMulticitySearchInfoError(true);
    }
  };

  const renderTripTypeAndTravelerPickers = () => {
    const numTravelerString =
      numTravelers < 2
        ? `${numTravelers} Traveler`
        : `${numTravelers} Travelers`;

    const appliedFareClassFilterCount = Object.keys(
      fareclassOptionFilter
    ).filter((fareclass) => fareclassOptionFilter[fareclass]).length;
    return (
      <Box
        className={clsx(
          "trip-type-and-traveler-pickers-container",
          config.TENANT,
          {
            "includes-fareclass-filter":
              showFareClassFilter || showNonStopToggle,
          }
        )}
      >
        <Box className="trip-type-and-traveler-pickers">
          <FlightCategoryRadio
            selectedCategory={tripCategory}
            setTripCategory={handleSetTripCategory}
            isMultiCityFlightsAvailable={isMultiCityEnabled}
          />
          <B2BButton
            aria-label={numTravelerString}
            className="num-travelers-input b2b"
            variant="traveler-selector"
            onClick={() => setOpenPassengerCountPicker(true)}
          >
            <Box className="num-traveler-content">
              <Icon
                aria-hidden={true}
                className="icon-start"
                name={IconName.B2BUser}
                ariaLabel=""
              />
              <Box className="text">{numTravelerString}</Box>
              <Icon
                aria-hidden={true}
                className="icon-end"
                name={IconName.Dropdown}
                ariaLabel=""
              />
            </Box>
          </B2BButton>
        </Box>
        <DesktopPopupModal
          open={openPassengerCountPicker}
          className="flight-desktop-passenger-count-picker-popup"
          contentClassName="desktop-passenger-count-picker-popup-container"
          onClose={() => setOpenPassengerCountPicker(false)}
          invisibleBackdrop={false}
          headerElement={textConstants.EDIT_TRAVELERS_TITLE}
        >
          <PassengerCountPicker
            minimumAdultsCount={1}
            onClickApply={handleTravelersChanged}
            className="b2b"
            includeChildrenInMaxCount
          />
        </DesktopPopupModal>
        {(showFareClassFilter || showNonStopToggle) && (
          <Box className="fareclass-nonstop-filters">
            {showFareClassFilter && (
              <FareclassOptionSelection
                fareclassOptionFilter={fareclassOptionFilter}
                setFareclassOptionFilter={setFareclassOptionFilter}
                hasSetFareclassFilter={hasSetFareclassFilter}
                appliedLabel={appliedFareClassFilterCount}
                labelIcon={IconName.FareIconFilled}
                includeClearFilter={false}
                popoverClassName="flight-search-fare-class-filter-popover"
              />
            )}
            {showNonStopToggle && (
              <NonStopToggle
                checked={stopsOption === SliceStopCountFilter.NONE}
                onClick={(checked) =>
                  setStopsOption(
                    checked
                      ? SliceStopCountFilter.NONE
                      : SliceStopCountFilter.ANY_NUMBER
                  )
                }
              />
            )}
          </Box>
        )}
        {isPolicyDescriptorsEnabled && (
          <>
            <Box className="policy-modal-button-container">
              <PolicyModalButton onClick={onShowPolicyDetailsModal} />
            </Box>
            <PolicyDetailsModal
              policies={policies}
              sessionInfo={sessionInfo}
              isOpen={isPolicyModalOpen}
              setIsOpen={setIsPolicyModalOpen}
              productType="flight"
            />
          </>
        )}
      </Box>
    );
  };

  const renderFlightSearchRow = () => {
    return (
      <Box
        className={clsx("flight-search-row", {
          "with-nonstop-toggle": showNonStopToggle,
        })}
      >
        <OriginDestinationSearch
          origin={origin}
          setOrigin={setOrigin}
          destination={destination}
          setDestination={setDestination}
          hasMissingSearchInfoError={hasMissingSearchInfoError}
          withSwap
          recentSearches={recentSearches}
          onRecentSearchClick={onRecentSearchClick}
        />
        <CalendarPickerButton
          onClick={() =>
            fetchDepartureCalendar(
              origin as ITripTerminus,
              destination as ITripTerminus
            )
          }
          classes={["date-pickers"]}
          saveDatesOnClose
          departureDate={departureDate}
          returnDate={returnDate}
          setDepartureDate={setDepartureDate}
          setReturnDate={setReturnDate}
          tripCategory={tripCategory}
          hasMissingSearchInfoError={
            hasMissingSearchInfoError &&
            !(tripCategory === TripCategory.ROUND_TRIP
              ? departureDate && returnDate
              : departureDate)
          }
        />
        <FlightSearchButton
          className="flight-search-button b2b"
          message={constants.SEARCH}
          onClick={handleSearchClick}
          enabled={true}
        />
      </Box>
    );
  };

  const renderFlightSearchRows = () => {
    if (isMultiCitySelected) {
      return (
        <MulticityFlightSearchRouteList
          hasMissingMulticitySearchInfoError={
            hasMissingMulticitySearchInfoError
          }
          renderErrorBanner={renderErrorBanner}
        />
      );
    }

    return renderFlightSearchRow();
  };

  const renderErrorBanner = () => {
    if (!hasMissingSearchInfoError && !hasMissingMulticitySearchInfoError)
      return;
    return (
      <Box className="missing-info-search-error-container">
        <NotificationBanner
          className="missing-info-search-error-banner"
          label={constants.MISSING_INFO_SEARCH_ERROR}
          severity={BannerSeverity.ERROR}
          icon={<Icon name={IconName.WarningAlert} />}
        />
      </Box>
    );
  };

  const renderMulticitySearchButton = () => {
    if (!isMultiCitySelected) return;
    return (
      <FlightSearchButton
        className="multicity flight-search-button b2b"
        message={constants.SEARCH}
        onClick={handleMulticitySearchClick}
        enabled={true}
      />
    );
  };

  const renderPackagesCTA = () => {
    const handlePackagesCTAClick = () => {
      const url = generatePackagesCTAUrl({
        origin: origin,
        destination: destination,
        fromDate: departureDate,
        untilDate: returnDate,
        stopsOption,
        fareClass: fareclassOptionFilter,
        adultsCount,
        childrenCount,
        infantsInSeatCount,
        infantsOnLapCount,
        matchesMobile: false,
        entryPoint: PackagesEntryTypeEnum.FLIGHT_SEARCH,
      });

      history.push(url);
    };

    return (
      <ButtonWrap
        className="flights-info-cta"
        onClick={() => handlePackagesCTAClick()}
      >
        <Box className="new-tag">New</Box>
        <Typography className="flights-info-cta-text">
          {INFO_NEW_PACKAGES_CTA_TEXT}
        </Typography>
        <Typography className="flights-info-cta-link">
          {INFO_NEW_PACKAGES_CTA_LINK}
        </Typography>
      </ButtonWrap>
    );
  };

  return (
    <Box className={clsx("flight-search", config.TENANT)}>
      {renderTripTypeAndTravelerPickers()}
      {renderFlightSearchRows()}
      {!isMultiCitySelected && renderErrorBanner()}
      {renderMulticitySearchButton()}
      {isPackagesExperiment && renderPackagesCTA()}
    </Box>
  );
};
