import * as React from "react";
import {
  StopsOptionSelection,
  MaxPriceFilterSelection,
  DepartureArrivalSelectionDropdown,
  AirlineFilterSelection,
  AirportFilterSelection,
  FlightNumberFilterSelection,
} from "../index";
import { config } from "../../../../../../../../api/config";
import { isCorpTenant, useShowPolicyBanner } from "@capone/common";
import { PolicyFilterSelection } from "../../../../../capone-corporate/PolicyFilterSelection";
import { ClientContext } from "../../../../../../../../App";
import { useContext } from "react";

export interface IDesktopFlightShopSearchFilterProps {
  hideAirportFilter?: boolean;
}

export const DesktopFlightShopSearchFilter = (
  props: IDesktopFlightShopSearchFilterProps
) => {
  const { hideAirportFilter } = props;
  const isCorporate = isCorpTenant(config.TENANT);
  const { policies, sessionInfo } = useContext(ClientContext);
  const showPolicyFilter = useShowPolicyBanner(policies, sessionInfo);

  return (
    <>
      <StopsOptionSelection />
      <MaxPriceFilterSelection />
      <AirlineFilterSelection />
      <DepartureArrivalSelectionDropdown />
      {!hideAirportFilter && <AirportFilterSelection />}
      <FlightNumberFilterSelection />
      {isCorporate && showPolicyFilter && <PolicyFilterSelection />}
    </>
  );
};
